import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import { useAuth0 } from "@auth0/auth0-react";
import Home from "./components/HomePage/Home";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Account from "./components/AccountPage/Account";
import NotFound from "./components/NotFound";
import Guild from "./components/Guild";
import GuildForm from "./components/GuildForm/GuildForm";
import JoinGuild from "./components/JoinGuild/JoinGuild";
import GuildJoined from "./components/GuildJoined/GuildJoined";
import Landing from "./components/Landing/Landing";

function App() {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const location = useLocation();

  const currentUrl = location.pathname;

  const handleLogin = () => {
    loginWithRedirect({ appState: { targetUrl: currentUrl } });
  };

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        // Now you can use this access token to make API calls
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const showNavBar =
    process.env.REACT_APP_STAGE == "dev" ||
    !location.pathname.includes("/joinguild") ||
    !location.pathname.includes("/guildjoined");

  const path = Cookies.get("guild-path");

  return (
    <div className="App">
      {isAuthenticated ? (
        <div>
          {showNavBar && <Navbar />}
          <Routes>
            <Route path="/joinguild/:id" element={<JoinGuild />} />
            <Route path="/guildjoined/*" element={<GuildJoined />} />

            <Route
              path="/"
              element={
                !path ? <Home /> : <Navigate to={`/joinguild/${path}`} />
              }
            />
            <Route path="/home" element={<Home />} />
            <Route path="/guildcreate" element={<GuildForm />} />
            <Route path="/account" element={<Account />} />
            <Route path="/guild/:id" element={<Guild />} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      ) : (
        <Landing handleLogin={handleLogin} />
        // <button onClick={(e) => loginWithRedirect()}>
        //   Login With Redirect
        // </button>
      )}
    </div>
  );
}

export default App;
