import React, { useState } from "react";

import "./DynamicTextField.css";

function DynamicTextField({ onFieldsChange }) {
  const [fields, setFields] = useState([""]);

  const addField = (e) => {
    e.preventDefault();
    setFields([...fields, ""]);
  };

  const deleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
    onFieldsChange(updatedFields); // Notify the parent component about the updated fields
  };

  const handleInputChange = (index, event) => {
    const updatedFields = [...fields];
    updatedFields[index] = event.target.value;
    setFields(updatedFields);
    onFieldsChange(updatedFields); // Notify the parent component about the updated fields
  };

  return (
    <div>
      <p className="questions-header">Additional Custom Questions(Optional)</p>

      {fields.map((field, index) => (
        <div className="custom-question" key={index}>
          <input
            type="text"
            value={field}
            onChange={(event) => handleInputChange(index, event)}
          />

          <button
            onClick={() => deleteField(index)}
            className="btn btn-square btn-outline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      ))}

      <button onClick={addField} className="btn btn-circle btn-outline">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 5V19M5 12H19"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}

export default DynamicTextField;
