import React, { useState, useEffect } from "react";

import { getGuildMembers } from "../../guildBackend/api";
import Member from "./Member";
import { useAuth0 } from "@auth0/auth0-react";

function MemberList(props) {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [members, setMembers] = useState(0);

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        const receivedToken = await getAccessTokenSilently();
        setAccessToken(receivedToken);
        // Now you can use this access token to make API calls
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken) {
      getGuildMembers(props.guildName, accessToken).then((res) => {
        setMembers(res);
      });
    }
  }, [accessToken]);
  if (members) {
    const memberList = Object.values(members);
    return (
      <ul>
        {memberList.map((member, index) => (
          <Member member={member} />
        ))}
      </ul>
    );
  }
}

export default MemberList;
