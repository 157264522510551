import React from "react";

const Card = (props) => {
  const number = props.number;
  const instructions = props.instructions;
  return (
    <div className="bg-white drop-shadow-md w-64 rounded-lg overflow-hidden mr-2 my-4 flex flex-col justify-between min-h-[190px]">
      <div className="px-1 py-4">
        <div className="font-bold text-xl mb-2 text-[#570DF8]">{number}</div>
        <p className="text-gray-700 px-4 text-lg"> {instructions}</p>
      </div>
    </div>
  );
};

export default Card;
