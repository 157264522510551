import React, { useRef, useEffect, useState } from "react";

import "./dropdown.css";

const Dropdown = (props) => {
  const { selectedOption, setSelectedOption, options } = props;

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  let menuRef = useRef();

  // handles the click outside functionality of the dropdown
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setIsOptionsVisible(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleSelected = (option) => {
    setSelectedOption(option);
    setIsOptionsVisible(false);
  };

  return (
    <div className="dropdown" ref={menuRef}>
      <label className="label">{props.title}</label>
      <div className="dropdown-component">
        <div className="selected-options">
          <div
            className="selected-options-wrapper"
            onClick={() => setIsOptionsVisible(!isOptionsVisible)}
          >
            <div>{selectedOption}</div>
            {/* These are the respective chevron-down & chevron-up arrows that are displayed when you open/close the dropdown */}
            {isOptionsVisible ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-chevron-up"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 15 12 9 18 15" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-chevron-down"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            )}
          </div>
          {isOptionsVisible && (
            <div className="dropdown-options">
              {" "}
              {options.length > 0 &&
                options.map((option) => (
                  <div
                    className="dropdown-option"
                    onClick={() => handleSelected(option)}
                  >
                    {option}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
