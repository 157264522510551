import React, { useState, useEffect } from "react";
import "./ChargesSummary.css";

function ChargesSummary(props) {
  //The fee is defined in other locations. Make sure to change payments.js and Landing.js
  const price = parseFloat(props.price);

  const fee = Math.ceil(0.01 * props.price * 100 + 50) / 100;

  const total = price + fee;
  return (
    <div className="ChargesSummary">
      <h3 className="ChargesTitle">Payment</h3>
      <table>
        <tbody>
          <tr>
            <td>Currency</td>
            <td>{props.currency}</td>
          </tr>
          <tr>
            <td>Membership Cost</td>
            <td>{price.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Service Fee</td>
            <td>{fee.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Grand Total</td>
            <td>{total.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ChargesSummary;
