import React, { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import {
  getUser,
  getConnectedAccountInfo,
  updateChargesEnabled,
  getConnectedAccountLink,
} from "../../guildBackend/api";

function Account() {
  const redirectUrl = process.env.REACT_APP_STRIPE_ACCOUNT_LINK_REDIRECT_URL;
  const refreshUrl = process.env.REACT_APP_STRIPE_ACCOUNT_LINK_REFRESH_URL;
  const [stripeURL, setStripeURL] = useState("");
  const [chargesEnabled, setChargesEnabled] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      try {
        const receivedToken = await getAccessTokenSilently();
        setAccessToken(receivedToken);
        // Now you can use this access token to make API calls
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken) {
      getUser(accessToken).then((res) => {
        getConnectedAccountInfo(res.stripe_account_id, accessToken).then(
          (userStripeAcct) => {
            const chargesEnabled = userStripeAcct.charges_enabled;

            updateChargesEnabled(chargesEnabled, accessToken).then(() => {
              setChargesEnabled(chargesEnabled);
            });
          }
        );

        getConnectedAccountLink(
          res.stripe_account_id,
          redirectUrl,
          refreshUrl,
          accessToken
        ).then((acctLink) => {
          setStripeURL(acctLink.url);
        });
      });
    }
  }, [accessToken]);

  return (
    <div className="Account">
      <h1>Account Info</h1>
      {chargesEnabled ? (
        <div>
          <h2>
            Thank you for setting up your Stripe account. You are ready to get
            paid
          </h2>
          <p>
            If you would like to make changes to or update your account, please
            do so{" "}
            <button className="btn btn-active btn-link">
              <a href={stripeURL}>here</a>
            </button>
          </p>
        </div>
      ) : (
        <p>
          Please set up or update your Stripe account to be able to create
          guilds. You can do so
          <button className="btn btn-active btn-link">
            <a href={stripeURL} color="blue">
              {" "}
              here
            </a>
          </button>
        </p>
      )}
    </div>
  );
}

export default Account;
