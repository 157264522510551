import React, { Component } from "react";


function NotFound() {
    return (
        <div className="NotFound">
            <h1>Sorry, Page Not Found</h1>
        </div>
    );
}

export default NotFound;
