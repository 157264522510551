import React from "react";

function Member(props) {
  const member = props.member;
  return (
    <li>
      <hr></hr>
      <ul>
        {Object.entries(member).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {value}
          </li>
        ))}
      </ul>
    </li>
  );
}

export default Member;
