const JoinGuildInput = (props) => {
  return (
    <div className="guildFormInput">
      <label className="label">{props.headLabel}</label>
      <input
        onChange={(e) => props.inputHandler(e.target.value)}
        placeholder={props.placeholder}
        type={props.type}
        maxLength={props.maxLength}
        pattern={props.pattern}
        min="1"
        max="100000"
        title={props.title}
        readOnly={props.readOnly}
        defaultValue={props.defaultValue}
      />
    </div>
  );
};

export default JoinGuildInput;
