import React from "react";

const Footer = () => {
  return (
    <footer className="container mx-auto p-6 flex flex-col md:flex-row items-center justify-between bottom-0 w-full">
      <p>Built with {"\u2764"}</p>
      <div className="flex -mx-6">
        <a href="#" className="mx-3 hover:opacity-80 duration-150">
          About us
        </a>
        <a href="#" className="mx-3 hover:opacity-80 duration-150">
          Terms of Service & Privacy
        </a>
        <a href="#" className="mx-3 hover:opacity-80 duration-150">
          Contact
        </a>
      </div>
    </footer>
  );
};

export default Footer;
