import React from "react";
import MemberList from "./MemberList";

function MemberModal(props) {
  const guildName = props.guildName;

  return (
    <div key={guildName} className="MemberList">
      {/* The button to open modal */}
      <label htmlFor={guildName} className="btn">
        See Members
      </label>


      {/* Put this part before </body> tag */}
      <input type="checkbox" id={guildName} className="modal-toggle" />
      <label htmlFor={guildName} className="modal cursor-pointer">
        <label className="modal-box relative" htmlFor="">
          <h3 className="text-lg font-bold">Your Members!</h3>
          <MemberList guildName={guildName} />
        </label>
      </label>
    </div>
  );
}

export default MemberModal;
