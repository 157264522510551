import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

function Navbar() {
  const { logout } = useAuth0();

  return (
    <div className="navbar bg-base-100">
      <div className="flex-1">
        <a className="btn btn-ghost normal-case text-xl">Memberwall</a>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          <li>
            <Link to="/Home">Home</Link>
          </li>
          <li>
            <Link to="/guildcreate">Create Guild</Link>
          </li>
          <li>
            <Link to="/account">Account Info</Link>
          </li>
          <li>
            <a onClick={(e) => logout()}>Log Out</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
