import { useState, useEffect } from "react";
import "../../App.css";
import DisplayGuilds from "./DisplayGuilds";
import { useAuth0 } from "@auth0/auth0-react";
import { getCreatedGuilds, createOrUpdateUser } from "../../guildBackend/api";

function Home() {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [guilds, setGuilds] = useState(0);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        const receivedToken = await getAccessTokenSilently();
        setAccessToken(receivedToken);
        // Now you can use this access token to make API calls
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken) {
      getCreatedGuilds(accessToken).then((res) => {
        setGuilds(res);
      });
      createOrUpdateUser(accessToken, user.email);
    }
  }, [accessToken]);

  return (
    <div className="Landing">
      <div>
        <h2>Welcome to Memberwall</h2>
        <br />
        <DisplayGuilds guilds={guilds} />
      </div>
    </div>
  );
}

export default Home;
