import "./DisplayGuilds.css";

import MemberModal from "./MemberModal";
import { getSignUpLink } from "../../common/functions";

const DisplayGuilds = (props) => {
  const data = props.guilds;

  return (
    <div className="DisplayGuilds">
      <div>
        <h2>Your Guilds:</h2>
        <br />
        {data && Object.keys(data).length > 0 ? (
          Object.keys(data).map((key) => (
            <span key={key} className="display_guilds">
              <div className="card w-96 bg-base-100 shadow-xl">
                <div className="card-body">
                  <h2 className="card-title">Guild: {key}</h2>
                  <p>Membership: {data[key].membership}</p>
                  <p>Limit: {data[key].limit}</p>
                  <p>
                    Price: {data[key].price} {data[key].selectedCurrency}
                  </p>
                  <h3>Link: {getSignUpLink(key)}</h3>
                  <MemberModal guildName={key} />
                </div>
              </div>
            </span>
          ))
        ) : (
          <p>Oh no, you haven't created any guilds :(</p>
        )}
      </div>
    </div>
  );
};

export default DisplayGuilds;
