import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import Cookies from "js-cookie";
import Companies from "./Companies";
import Directions from "./Directions";
import Footer from "./Footer";

function Landing({ handleLogin }) {
  const location = useLocation();

  const pathSegments = location.pathname.split("/");

  const guildPath = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    // Function to add the new cookie after the old one is deleted
    function addNewCookie(cookiename, value) {
      if (pathSegments.length == 3 && pathSegments[1] == "joinguild") {
        Cookies.set(cookiename, value, { expires: 10 / 24 });
      }
    }

    addNewCookie("guild-path", guildPath);
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-center items-center overflow-scroll">
      <div className="flex w-full flex-col items-center">
        {!location.pathname.includes("/joinguild") ? (
          <div className="min-h-screen w-full">
            <div className="h-24"></div>
            <main className="container mx-auto px-6 pt-16 text-center"></main>

            <h2 className="text-2xl md:text-4xl lg:text-6xl uppercase">
              Welcome to
            </h2>
            <h1 className="text-3xl md:text-6xl lg:text-8xl font-black uppercase mb-8">
              MemberWall
            </h1>
            <div className="justify-center text-lg md:text-2xl bg-opacity-10">
              The easiest way to enable paid memberships.
            </div>
            <br />
            <div className="opacity-75 italic">
              By signing up, you agree with MemberWall's{" "}
              <a
                target="_blank"
                href="https://storage.googleapis.com/memberwall_asset/Terms.html"
              >
                Terms of Service and Privacy Policy
              </a>{" "}
            </div>
            <br />
            <button
              className="btn btn-outline btn-primary"
              onClick={handleLogin}
            >
              Signin
            </button>

            <Companies />
            <Directions />

            <div className="flex-1 grid card bg-white shadow-md rounded-lg p-6 m-2">
              <h2 className="text-3xl font-bold text-gray-900 mb-3 ">
                Pricing
              </h2>
              <p className="text-lg text-gray-700">
                <span className="font-bold">Memberwall Fee:</span> 1% + 0.5(in
                the currency being charged).
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-bold">Credit Card Processing Fee:</span>
                This fee is seperate from the{" "}
                <span className="font-semibold">Memberwall fee</span>. Starts at
                2.9% + 0.3(In the currency being charged). This may vary.
              </p>
            </div>
            <Footer />
          </div>
        ) : (
          <div className="join_landing h-full">
            <main className="container mx-auto px-6 pt-16 text-center"></main>

            <h2 className="text-2xl md:text-4xl lg:text-6xl uppercase">
              Welcome to
            </h2>
            <h1 className="text-3xl md:text-6xl lg:text-8xl font-black uppercase mb-8">
              MemberWall
            </h1>
            <div className="text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6 lg:px-12 bg-opacity-10 w-fit mb-1">
              To register for the Guild:
              <span className="font-bold"> {guildPath}</span>, please signin
              below
            </div>

            <br />
            <div className="opacity-75 italic">
              By signing up, you agree with MemberWall's{" "}
              <a
                target="_blank"
                href="https://storage.googleapis.com/memberwall_asset/Terms.html"
              >
                Terms of Service and Privacy Policy
              </a>{" "}
            </div>
            <br />

            <button
              className="btn btn-outline btn-primary"
              onClick={handleLogin}
            >
              Sign-in
            </button>
            <Footer />
          </div>
        )}

        <br />
      </div>
    </div>
  );
}

export default Landing;
