// api.js
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to create config with Authorization header
const getAuthConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

function getUserFromToken(token) {
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid JWT token format");
  }

  const payload = parts[1];
  const decodedPayload = atob(payload.replace(/-/g, "+").replace(/_/g, "/"));
  const payloadObj = JSON.parse(decodedPayload);

  return payloadObj.sub.split("|")[1];
}

export const getCreatedGuilds = async (token) => {
  const userId = getUserFromToken(token);
  return (
    await axios.get(
      `${BASE_URL}/created-guilds/${userId}`,
      getAuthConfig(token)
    )
  ).data;
};

export const getGuildMembers = async (guildName, token) => {
  return (
    await axios.get(
      `${BASE_URL}/guild-members/${guildName}`,
      getAuthConfig(token)
    )
  ).data;
};

export const updateChargesEnabled = async (val, token) => {
  const userid = getUserFromToken(token);
  return (
    await axios.post(
      `${BASE_URL}/update-charges-enabled`,
      { userid, val },
      getAuthConfig(token)
    )
  ).data;
};

export const getGuildInfo = async (guildName, token) => {
  return (
    await axios.get(`${BASE_URL}/guild-info/${guildName}`, getAuthConfig(token))
  ).data;
};

export const getUser = async (token, userId = null) => {
  if (userId == null) {
    userId = getUserFromToken(token);
  }

  return (await axios.get(`${BASE_URL}/user/${userId}`, getAuthConfig(token)))
    .data;
};

export const submitGuildCreate = async (
  guildName,
  price,
  limit,
  selectedCurrency,
  additionalQuestions,
  token
) => {
  const userId = getUserFromToken(token);
  const guildDetails = {
    guildName,
    price,
    limit,
    selectedCurrency,
    userId,
    additionalQuestions,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/submit-guild-create`,
      guildDetails,
      getAuthConfig(token)
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting guild create:", error);
    throw error;
  }
};

export const submitPaymentIntent = async (
  paymentIntent,
  id,
  email,
  userName,
  answers,
  token
) => {
  const paymentDetails = {
    payment_intent: paymentIntent,
    id,
    email,
    user_name: userName,
    answers,
  };

  return (
    await axios.post(
      `${BASE_URL}/submit-payment-intent`,
      paymentDetails,
      getAuthConfig(token)
    )
  ).data;
};

export const deletePaymentIntent = async (paymentIntent, token) => {
  return (
    await axios.delete(
      `${BASE_URL}/delete-payment-intent/${paymentIntent}`,
      getAuthConfig(token)
    )
  ).data;
};

export const createOrUpdateUser = async (token, email) => {
  const userid = getUserFromToken(token);
  return (
    await axios.post(
      `${BASE_URL}/create-or-update-user`,
      { userid, email },
      getAuthConfig(token)
    )
  ).data;
};

export const getPaymentIntentInfo = async (
  amount,
  currency,
  account_id,
  token
) => {
  const paymentIntentDetails = {
    amount,
    currency,
    account_id,
  };
  return (
    await axios.post(
      `${BASE_URL}/get-payment-intent-info`,
      paymentIntentDetails,
      getAuthConfig(token)
    )
  ).data;
};

export const getConnectedAccountInfo = async (acct, token) => {
  return (
    await axios.get(
      `${BASE_URL}/get-connected-account-info/${acct}`,
      getAuthConfig(token)
    )
  ).data;
};

export const getConnectedAccountLink = async (
  stripe_account_id,
  redirectUrl,
  refreshUrl,
  token
) => {
  const linkDetails = {
    stripe_account_id,
    redirect_url: redirectUrl,
    refresh_url: refreshUrl,
  };
  return (
    await axios.post(
      `${BASE_URL}/get-connected-account-link`,
      linkDetails,
      getAuthConfig(token)
    )
  ).data;
};

export const getAdditionalQuestions = async (guild, token) => {
  return (
    await axios.get(
      `${BASE_URL}/get-additional-questions/${guild}`,
      getAuthConfig(token)
    )
  ).data;
};
