import React, { useState, useEffect } from "react";
import { CURRENCY_OPTIONS } from "../../common/currency";
import Dropdown from "../../formHelpers/Dropdown";
import GuildFormInput from "./GuildFormInput";
import DynamicTextField from "./DynamicTextField";
import { submitGuildCreate } from "../../guildBackend/api";
import { getUser } from "../../guildBackend/api";

import { useAuth0 } from "@auth0/auth0-react";

function GuildForm() {
  const [guildName, setGuildName] = useState("");
  const [price, setPrice] = useState("");
  const [limit, setLimit] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_OPTIONS[0]);
  const [chargesEnabled, setChargesEnabled] = useState(false);
  const [additionalQuestions, setAdditionalQuestions] = useState([""]);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      try {
        const receivedToken = await getAccessTokenSilently();
        setAccessToken(receivedToken);
        // Now you can use this access token to make API calls
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken && user.email) {
      // Ensure both accessToken and user.email are available
      getUser(accessToken)
        .then((res) => {
          setChargesEnabled(res.charges_enabled);
        })
        .catch((error) => {
          // Handle potential errors, such as a failed API call
          console.error("Failed to fetch user data", error);
        });
    }
  }, [accessToken, user.email]);

  const handleFieldsChange = (updatedFields) => {
    setAdditionalQuestions(updatedFields);
  };

  const inputs = [
    {
      id: 0,
      name: "guildname",
      type: "text",
      maxLength: 15,
      pattern: "^[a-z0-9]+$",
      placeholder: "Guild Name",
      label: "Guild Name",
      title: "Please enter only lowercase letters or numbers",
      handler: setGuildName,
    },

    {
      id: 2,
      name: "price",
      type: "text",
      placeholder: "Price",
      pattern: "^\\d+(\\.\\d{1,2})?$",
      label: "Price",

      title:
        "Please only enter numbers greater than 1 and up to two decimal points(if needed)",
      handler: setPrice,
    },
    {
      id: 3,
      name: "limit",
      type: "number",
      pattern: "[0-9]+",
      maxLength: 0,
      placeholder: "Limit",
      label: "Limit",
      title: "",
      handler: setLimit,
    },
  ];

  const handleGuildSubmit = async (e) => {
    e.preventDefault();
    submitGuildCreate(
      guildName,
      price,
      limit,
      selectedCurrency,
      additionalQuestions,
      accessToken
    )
      .then((response) => {
        setIsSubmitError(false); // Reset or clear error state if previously set
        // Directly alert the success message from the backend
        alert(response.message);
      })
      .catch((error) => {
        setIsSubmitError(true); // Indicate an error state
        // Directly alert the error message
        alert(
          error.response
            ? error.response.data.message
            : "Network or server error"
        );
      });
  };

  return (
    <div className="GuildForm">
      {chargesEnabled ? (
        <form onSubmit={handleGuildSubmit}>
          <h1>Create Guild</h1>

          <Dropdown
            options={CURRENCY_OPTIONS}
            selectedOption={selectedCurrency}
            setSelectedOption={setSelectedCurrency}
            title={"Currency"}
          />

          {inputs.map((input, idx) => {
            return (
              <GuildFormInput
                key={idx}
                headLabel={input.label}
                placeholder={input.placeholder}
                inputHandler={input.handler}
                type={input.type}
                maxLength={input.maxLength}
                pattern={input.pattern}
                title={input.title}
              />
            );
          })}

          <br></br>

          <DynamicTextField onFieldsChange={handleFieldsChange} />

          <br />
          <br />

          <button type="submit" className="btn btn-outline">
            Submit
          </button>
        </form>
      ) : (
        <div>
          Sorry, you cannot create a Guild yet. Please complete your payment
          information under the 'Account Info' tab
        </div>
      )}
    </div>
  );
}

export default GuildForm;
