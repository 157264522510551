import React, { useEffect, useState, useMemo } from "react";
import PaymentStatus from "./PaymentStatus";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function GuildJoined() {
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  const guildId = new URLSearchParams(window.location.search).get("id");

  const stripePromise = useMemo(() => {
    return loadStripe(publishableKey);
  });

  return (
    <Elements stripe={stripePromise}>
      <div className="GuildJoined">
        <h1>
          Thanks! Once your transaction is confirmed, you will be added to{" "}
          {guildId}
        </h1>
      </div>
    </Elements>
  );
}

export default GuildJoined;
