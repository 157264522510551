import { useParams, useNavigate } from "react-router-dom";
import { getGuildInfo, getGuildMembers } from "../guildBackend/api";
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import QRCode from "react-qr-code";
import { getSignUpLink } from "../common/functions";

function Guild() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [currency, setCurrency] = useState(0);
  const [price, setPrice] = useState(0);
  const [membership, setMembership] = useState(0);
  const [limit, setLimit] = useState(0);
  const [creator, setCreator] = useState(0);
  const [members, setMembers] = useState(0);
  const { id } = useParams();

  const navigate = useNavigate;

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        const receivedToken = await getAccessTokenSilently();
        setAccessToken(receivedToken);
        // Now you can use this access token to make API calls
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken) {
      getGuildInfo(id, accessToken).then((res) => {
        if (res == null) {
          navigate("../NotFound");
        }
        setCurrency(res.selectedCurrency);
        setPrice(res.price);
        setMembership(res.membership);
        setLimit(res.limit);
        setCreator(res.creator);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      getGuildMembers(id, accessToken).then((res) => {
        setMembers(JSON.stringify(res));
      });
    }
  }, []);

  return (
    <div className="Guild">
      <h1>Guild: {id}</h1>
      <h1>
        Price: {price} {currency}
      </h1>
      <h1>Membership: {membership}</h1>
      <h1>Limit: {limit}</h1>
      <h1>Creator: {creator}</h1>
      <h1>Members: {members}</h1>
      <h1>Scan this QR code to sign up:</h1>
      <QRCode value={getSignUpLink(id)} style={{ display: "inline-block" }} />
    </div>
  );
}

export default Guild;
