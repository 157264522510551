import React, { useEffect, useState, useMemo } from "react";
import Cookies from "js-cookie";

import { useParams, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useAuth0 } from "@auth0/auth0-react";

import {
  getPaymentIntentInfo,
  getGuildInfo,
  getUser,
} from "../../guildBackend/api";

import JoinGuildForm from "./JoinGuildForm";

function JoinGuild() {
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  const [guildOwner, setGuildOwner] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [stripeAccount, setStripeAccount] = useState(null);

  const [paymentIntentSec, setPaymentIntentSec] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const memoizedPaymentIntent = useMemo(() => paymentIntent, [paymentIntent]);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { id } = useParams();
  const navigate = useNavigate();

  const path = Cookies.get("guild-path");
  if (path) {
    Cookies.remove("guild-path");
  }

  const stripePromise = useMemo(() => {
    return loadStripe(publishableKey, {
      stripeAccount: stripeAccount,
    });
  }, [stripeAccount]);

  const options = {
    // pass the client secret from the previous step
    clientSecret: paymentIntentSec,
  };

  function convertPrice(val) {
    const decimalNumber = parseFloat(val); // Convert string to decimal
    const multipliedNumber = decimalNumber * 100; // Multiply by 100
    const integerNumber = Math.round(multipliedNumber);
    return integerNumber;
  }

  useEffect(() => {
    const getToken = async () => {
      try {
        const receivedToken = await getAccessTokenSilently();
        setAccessToken(receivedToken);
        // Now you can use this access token to make API calls
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken) {
      getGuildInfo(id, accessToken)
        .then((res) => {
          if (res == null) {
            navigate("/notfound");
          }
          setCurrency(res.selectedCurrency);
          setPrice(res.price);
          setGuildOwner(res.creator);
          return Promise.all([
            res.price,
            res.selectedCurrency,
            getUser(accessToken, res.creator),
          ]);
        })
        .then((res) => {
          setStripeAccount(res[2].stripe_account_id);
          const convertedPrice = convertPrice(res[0]);
          return getPaymentIntentInfo(
            convertedPrice,
            res[1].toLowerCase(),
            res[2].stripe_account_id,
            accessToken
          );
        })
        .then((res) => {
          setPaymentIntentSec(res.client_secret);
          setPaymentIntent(res.id);
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error(error);
          navigate("/notfound");
        });
    }
  }, [accessToken]);

  return (
    paymentIntentSec && (
      <Elements stripe={stripePromise} options={options}>
        <JoinGuildForm
          user={user}
          currency={currency}
          price={price}
          guildOwner={guildOwner}
          paymentIntent={memoizedPaymentIntent}
        />
      </Elements>
    )
  );
}

export default JoinGuild;
