import React from "react";
import Card from "./Card";

const Directions = () => {
  return (
    <div className="w-full bg-[#f3edff] py-24">
      <div className="md:max-w-[2000px] m-auto max-w-[600px]">
        <h1 className="py-3 text-3xl font-bold mb-4">
          How To Start <span className="text-[#570DF8]">Charging</span>
        </h1>

        <div className="flex flex-wrap  justify-center gap-4">
          <Card number="1" instructions="Signin with your service of choice" />
          <Card
            number="2"
            instructions="Go to the 'Account Info' section and set up your Stripe account to accept credit card payments"
          />
          <Card
            number="3"
            instructions="Click on 'Create Guild' to create a guild."
          />
          <Card
            number="4"
            instructions="Send the shareable link for the created guild to potential members"
          />
        </div>
      </div>
    </div>
  );
};

export default Directions;
