import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./JoinGuildForm.css";

import JoinGuildInput from "./JoinGuildInput";

import ChargesSummary from "../ChargesSummary/ChargesSummary";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getAdditionalQuestions,
  submitPaymentIntent,
  deletePaymentIntent,
} from "../../guildBackend/api";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

let answers = {};

function JoinGuildForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [questions, setQuestions] = useState("");
  const [accessToken, setAccessToken] = useState(null);

  const { id } = useParams();

  const user = props.user;
  const paymentIntent = props.paymentIntent;
  const price = props.price;
  const currency = props.currency;

  function updateQuestions(index, answer) {
    answers[index].answer = answer;
  }

  useEffect(() => {
    const getToken = async () => {
      try {
        const receivedToken = await getAccessTokenSilently();
        setAccessToken(receivedToken);
        // Now you can use this access token to make API calls
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken) {
      getAdditionalQuestions(id, accessToken).then((res) => {
        setQuestions(res);
        answers = res;
      });
    }
  }, [accessToken]);

  const inputs = [
    {
      id: 0,
      name: "guildname",
      type: "text",
      maxLength: 15,
      label: "Guild Name",
      readOnly: true,
      defaultValue: id,
    },
    {
      id: 1,
      name: "email",
      type: "text",
      label: "Email",
      handler: setEmail,
      readOnly: true,
      defaultValue: user.email,
    },
    {
      id: 2,
      name: "name",
      type: "text",
      pattern: "[a-zA-Z]+",
      maxLength: 20,
      label: "Name",
      handler: setName,
      defaultValue: user.name,
    },
  ];

  const handleGuildSubmit = async (e) => {
    e.preventDefault();

    submitPaymentIntent(
      paymentIntent,
      id,
      user.email,
      user.name,
      answers,
      accessToken
    );

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const joinedUrl = `${process.env.REACT_APP_DOMAIN_NAME}/guildjoined?id=${id}`;

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: joinedUrl,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // setErrorMessage(error.message);
      console.log("Error with PI", paymentIntent);
      deletePaymentIntent(paymentIntent, accessToken);
      alert(error.message);
      setErrorMessage(error.message);

      // alert(`Sorry there was an error processing the payment: ${errorMessage}`);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div className="GuildForm">
      <form onSubmit={handleGuildSubmit}>
        <h1>Join Guild:{id}</h1>

        {inputs.map((input, idx) => {
          return (
            <JoinGuildInput
              key={idx}
              headLabel={input.label}
              placeholder={input.placeholder}
              inputHandler={input.handler}
              type={input.type}
              maxLength={input.maxLength}
              readOnly={input.readOnly}
              defaultValue={input.defaultValue}
            />
          );
        })}

        {questions &&
          questions.map((element, index) => {
            return (
              element.question && (
                <div>
                  <p>
                    {element.question} ({element.type})
                  </p>
                  <input
                    onChange={(e) => updateQuestions(index, e.target.value)}
                  />
                </div>
              )
            );
          })}

        <br />
        <ChargesSummary price={price} currency={currency} />
        <br />

        <div class="PaymentElement">
          <PaymentElement />
        </div>
        <br />

        <button disabled={!stripe} type="submit" className="btn btn-outline">
          Submit
        </button>
      </form>
    </div>
  );
}

export default JoinGuildForm;
