import React from "react";
import WPTC_logo from "../../assets/WPTC_logo.png";

const Companies = () => {
  return (
    <div className="w-full bg-white py-[25px]">
      <div className="md:max-w-[1480px] m-auto max-w-[600px]">
        {/* <h1 className="text-center text-2xl font-bold text-[#1F2937]">
          Trusted by clubs and organizations
        </h1> */}
        <p className="text-center text-[#1F2937] text-xl">
          Trusted by clubs and organizations
        </p>
        <div className="flex justify-center py-2 md:gap-8">
          <img src={WPTC_logo} />
        </div>
      </div>
    </div>
  );
};

export default Companies;
